import { toast } from "react-toastify";
import {
  PostOrder,
  PostHistoricOrder,
  PostApiyBrouillon,
  VerifyCustomerInCmd,
  VerifyProductInCmd,
  getCity,
  getElementOfNumebrVerify,
  getProvince,
  validatePhoneNumber,
  PostAllOrdersListByTypeOrder,
  getAllOrdersTypeDelivrey,
  ToastStatus,
  UpdateOrdersMaystro,
  getDelivreyByProvinces,
  transformDelivreyProvinceData,
} from "./services";
import axios from "axios";
import Swal from "sweetalert2";
const apiURL = process.env.REACT_APP_API_URL;

const getQte = (element) => {
  return getElementOfNumebrVerify(element.qty);
};
const getPrice = (element) => {
  return getElementOfNumebrVerify(element.price);
};
const getFrais = (element) => {
  return getElementOfNumebrVerify(element?.delivrey);
};
const getReduction = (element) => {
  return getElementOfNumebrVerify(element?.reduction);
};
// const getMontant = (element) => {
//   return getElementOfNumebrVerify(element?.montant);
// };
const getPanier = (element) => {
  return element.products.map((product) => {
    return {
      ref: product.ref,
      price: getPrice(product),
      qty: getQte(product),
    };
  });
};
let index = 0;
export const processNextOrder = (commandes, boutiquesSelected, auth, type) => {
  if (index < commandes?.length) {
    // Tant qu'il y a des commandes à traiter
    const element = commandes[index];
    PostOrderApi(element, boutiquesSelected, auth, type);
    // // Incrémentez l'index pour la prochaine itération
    index++;
    // Attendez 2 secondes avant de traiter la prochaine commande
    // setTimeout(processNextOrder, 2000); // 2000 millisecondes (2 secondes)
  }
};

const FormBrouillon = (element, id_customer, id_province, id_city, type) => {
  const form = new FormData();
  form.append("id_shop", element?.id_shop);
  form.append("id_customer", id_customer);
  form.append("id_province", id_province);
  form.append("id_city", id_city);
  form.append("customer", element.name_customer);
  form.append("phone", element.phone);
  form.append("email", element.email);
  form.append("province", element.name_province);
  form.append("city", element.name_city);
  form.append("address", element.address);
  form.append("cart", JSON.stringify(getPanier(element)));
  form.append("reduction", getReduction(element));
  form.append("delivrey", getFrais(element));
  form.append("referent", element.referent);
  form.append("methode", 1);
  form.append("prefix", element.prefix_order);
  // form.append("montant", getMontant(element));
  form.append("type", type);
  const obj = Object.fromEntries(form);
  return obj;
};

const NotValidateRefOfProducts = async (products) => {
  let HasError = false;
  if (products?.length > 0) {
    for (const element of products) {
      try {
        const resp = await VerifyProductInCmd(element.ref);

        if (resp?.errors[0]?.ErrorRef === 0) {
          HasError = true;
          break;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }
  return HasError;
};

const PostOrderApi = (element, boutiquesSelected, auth, type) => {
  let id_province = 0;
  let id_city = 0;
  let id_customer = 0;
  const phone = element?.phone;
  NotValidateRefOfProducts(element.products).then((resp) => {
    Promise.all([
      VerifyCustomerInCmd(phone),
      // ajouter verfi in listeExcel
      getProvince(element?.name_province),
      getCity(element?.name_city),
    ]).then(([customerObjet, province, city]) => {
      if (
        province.length === 1 &&
        city.length === 1 &&
        validatePhoneNumber(phone) &&
        !resp
        // error?.errors[0]?.ErrorRef === 1 &&
        // clientObjet?.errors[0]?.msg  &&
        // element[1] != ""
      ) {
        let customer = 0;
        !customerObjet?.isNew && (customer = customerObjet[0]?.id_customer);
        const formDataFile = new FormData();
        formDataFile.append("id_customer", customer);
        formDataFile.append("id_shop", boutiquesSelected?.id_shop);
        formDataFile.append("prefix", element?.prefix_order);
        formDataFile.append("type", 1);
        formDataFile.append("methode", 1);
        formDataFile.append("remarques", "");
        formDataFile.append("referent", element?.referent);
        formDataFile.append("delivrey", getFrais(element));
        formDataFile.append("reduction", getReduction(element));
        // formDataFile.append("montant", getMontant(element));
        formDataFile.append("cart", JSON.stringify(getPanier(element)));
        formDataFile.append("name", element?.name_customer);
        formDataFile.append("phone", element?.phone);
        formDataFile.append("email", element?.email);
        formDataFile.append("address", element?.address);
        formDataFile.append("province", province[0]?.id);
        formDataFile.append("city", city[0]?.id);
        formDataFile.append("action", 1);
        formDataFile.append("api", true);
        formDataFile.append("user", auth?.idUser);
        const obj = Object.fromEntries(formDataFile);
        PostOrder(obj).then((response) => {
          PostHistoricOrder(
            1,
            auth?.idUser,
            "-",
            element?.prefix_order,
            true
          ).then(() => {});
        });
      } else {
        id_customer =
          !customerObjet?.isNew &&
          customerObjet?.length > 0 &&
          !customerObjet?.errors?.[0]?.msg
            ? customerObjet[0].id_customer
            : id_customer;

        id_province = province.length > 0 ? province[0].id : id_province;
        id_city = city.length > 0 ? city[0].id : id_city;
        const objet = FormBrouillon(
          element,
          id_customer,
          id_province,
          id_city,
          type
        );
        PostApiyBrouillon(objet);
      }
    });
  });
};
export const VerifyOrdersInCmdOrBrouillon = async (orders) => {
  const resp = await axios.post(apiURL + "/drafts/orders", {
    orders: orders,
  });
  return resp.data;
};
export const GetFilterOrders = (data, orders) => {
  let filterOrders = [];
  let filterOrder;
  if (data.length > 0) {
    data.forEach((element) => {
      filterOrder = orders.find((order) => order.prefix_order != element.id);
      if (filterOrder) {
        filterOrders.push(filterOrder);
      }
    });
  } else {
    filterOrders = orders;
  }
  return filterOrders;
};

export const ListBrouillons = (data) => {
  if (data && data?.length > 0) {
    const result = data?.reduce((acc, item) => {
      // Check if an entry for this shopify_brouillon already exists
      const existingEntry = acc.find(
        (entry) => entry.idraft_api === item.idraft_api
      );

      if (existingEntry) {
        // If entry exists, push the product details to its 'products' array
        existingEntry.products.push({
          idlist_dr_api: item.idlist_dr_api,
          ref: item.product_id,
          qty: item.qty,
          price: item.price,
          api_id: item.api_id,
        });
      } else {
        // If entry doesn't exist, create a new entry with product details
        acc.push({
          idraft_api: item.idraft_api,
          id_shop: item.shop_id,
          id_customer: item.customer_id,
          id_province: item.province_id,
          id_city: item.city_id,
          customer: item.customer,
          delivrey: item.delivrey,
          reduction: item.reduction,
          phone: item.phone,
          email: item.email,
          province: item.province,
          city: item.city,
          address: item.address,
          referent: item.referent,
          methode: item.methode,
          prefix_draft: item.prefix_draft,
          api_draft: item.api_draft,
          create_time: item.create_time,
          products: [
            {
              idlist_dr_api: item.idlist_dr_api,
              ref: item.product_id,
              qty: item.qty,
              price: item.price,
              api_id: item.api_id,
            },
          ],
        });
      }

      return acc;
    }, []);

    return result;
  }
  return [];
};

export const PostYalitecOrder = async (
  orders,
  api_key_yalitec,
  api_token_yalitec
) => {
  await axios.post(apiURL + "/yalitec", {
    orders: orders,
    key: api_key_yalitec,
    token: api_token_yalitec,
  });
};

export const ListeOrderstOfYalitec = (itemsApi) => {
  return itemsApi?.map((objet) => {
    return {
      prefix_order: objet?.tracking,
      name_shop: objet?.assignments?.store_snapshot?.name,
      name_customer: objet?.firstname + " " + objet?.familyname,
      phone: objet?.contact_phone,
      name_province: objet?.to_wilaya_name,
      name_city: objet?.to_commune_name,
      address: objet?.address,
      note: objet?.product_list,
      cost_delivrey: objet?.delivery_fee,
      amount: objet?.price,
      name_delivrey: "Yalitec",
      create_time: objet?.date_creation,
      print: objet?.label,
      products: [],
    };
  });
};
// const getOrdersFromApi = (boutiques, status) => {
//   let arrayOrders = [];
//   let errorCharge = false;

//   boutiques.forEach((boutique) => {
//     getAllOrdersApi(
//       boutique.api_key_yalitec,
//       boutique.api_token_yalitec,
//       status
//     )
//       .then((itemsApi) => {
//         return itemsApi?.data;
//       })
//       .catch((error) => {
//         // setErrorNetwork(true);
//         return [];
//       });
//   });
// };
export const getOrdersDBAndYalitecAPi = (
  type,
  user,
  role,
  branch,
  setOrders,
  setLoaded,
  setOrdersPrint,
  setLoadedPrint
) => {
  // auth?.idUser
  getAllOrdersTypeDelivrey(type, user, role, branch)
    .then((dbOrders) => {
      if (parseInt(type) === 3) {
        const formattedOrders = dbOrders.reduce((acc, order) => {
          const existingOrder = acc.find((o) => o.id_order === order.id_order);
          if (!existingOrder) {
            acc.push({
              id_order: order.id_order,
              displayId: order.displayId,
              prefix_order: order.prefix_order,
              name_shop: order.name_shop,
              name_customer: order.name_customer,
              name_province: order.name_province,
              name_city: order.name_city,
              cost_delivrey: order.cost_delivrey,
              username: order.username,
              amount: order.amount,
              phone: order.phone,
              name_delivrey: order.name_delivrey,
              synchrone: order.synchrone,
              note: order.note,
              id_shop: order.id_shop,
              api_order: order.api_order,
              id_city: order.id_city,
              id_province: order.id_province,
              create_time: order.create_time,
              delivrey_method: order.delivrey_method,
              id_delivrey: order.id_delivrey,
              address: order.address,
              print: order.print,
              reduction: order.reduction,
              name_product: order.name_product,
              qty: order.qty,
              price: order.price,
              productDesc: order.productDesc,
              image: order.image,
              ref: order.ref,
              settings: [
                {
                  id_setting: order.idsetting_api,
                  name_setting: order.name_setting,
                  column1: order.column1,
                  column2: order.column2,
                  active: order.active,
                },
              ],
            });
          } else {
            existingOrder.settings.push({
              id_setting: order.idsetting_api,
              name_setting: order.name_setting,
              column1: order.column1,
              column2: order.column2,
              active: order.active,
            });
          }
          return acc;
        }, []);
        if (setOrdersPrint) {
          setOrdersPrint(formattedOrders);
        }
        setOrders(formattedOrders);
      } else {
        if (setOrdersPrint) {
          setOrdersPrint(dbOrders);
        }
        setOrders(typeof dbOrders === "string" ? [] : dbOrders);
      }
    })
    .finally(() => {
      setLoaded(false);
    });
};
export const CheckSelectedOrder = (
  setButtonDisabled,
  setButtonDisabledSelect,
  countSelected,
  delivrey
) => {
  let checkedValue = true;
  let checkedValueSelect = true;
  countSelected.length > 0 ? (checkedValue = false) : (checkedValue = true);
  setButtonDisabled(checkedValue);
  !checkedValue && delivrey !== null
    ? (checkedValueSelect = false)
    : (checkedValueSelect = true);
  setButtonDisabledSelect(checkedValueSelect);
};
export const CheckSelectedOrderDispatch = (
  setButtonDisabled,
  setButtonDisabledSelect,
  countSelected,
  delivrey,
  delivreyExpedier,
  delivreyDecharge
) => {
  let checkedValue = true;
  let checkedValueSelect = true;
  countSelected.length > 0 ? (checkedValue = false) : (checkedValue = true);
  setButtonDisabled(checkedValue);
  if (
    (!checkedValue && delivrey !== null) ||
    (!checkedValue && delivreyExpedier !== null) ||
    (!checkedValue && delivreyDecharge !== null)
  ) {
    checkedValueSelect = false;
  } else {
    checkedValueSelect = true;
  }
  setButtonDisabledSelect(checkedValueSelect);
};

export const AddPanierOfOrder = (
  countSelected,
  setButtonDisabledAPI,
  setPanier,
  orders
) => {
  setButtonDisabledAPI(false);
  if (orders?.length > 0) {
    orders.forEach((order) => {
      if (order.synchrone === 1) {
        setButtonDisabledAPI(true);
        return;
      }
    });
    // CheckOrderAPi(countSelected).then((resp) => {
    //   const check = resp[0]?.nbrOrder === 0 ? false : true;
    //   if (check) {
    //     setButtonDisabledAPI(false);
    //     setPanier(SelectedCmd.products);
    //   } else {
    PostAllOrdersListByTypeOrder(countSelected).then((items) => {
      setPanier(items);
      // });
      // }
    });
  }
};
const getMessageOrders = (msg, setChangeType) => {
  toast.success(msg, {
    ...ToastStatus,
  });
};
export const Synchroniser = (
  type,
  auth,
  setChangeType,
  setLoaded,
  setOrders
) => {
  setLoaded(true);
  UpdateOrdersMaystro(type, auth)
    .then((data) => {
      setOrders(data);
      setLoaded(false);
      getMessageOrders("Commande(s) synchroniser");
    })
    .catch((error) => {
      console.log(error);
      setLoaded(false);
      toast.error("Impossible de synchroniser. Veuillez réessayer ");
    })
    .finally(() => {
      setChangeType((prev) => !prev);
    });
};
export const ChangeToOrdersYalitecOrYalidine = (
  orders,
  panier,
  centerSelected,
  provinceSelected
) => {
  return orders.map((order) => {
    const products = panier.filter(
      (objet) => objet.order_id === order.prefix_order
    );
    let name = [];
    if (products?.length > 0) {
      products.forEach((element) => {
        // element.options.forEach((option) => {
        //   options.push(`${option.name} : ${option.value} `);
        // });
        // const myArray = options.map((obj) => JSON.stringify(obj)).join(", ");
        name.push(
          `( Produit : ${element.name_product},qte : ${element.qty} ) `
        );
      });
    } else {
      name.push(order.note);
    }
    const list = name.join(" , ");
    return {
      order_id: order.prefix_order,
      from_wilaya_name: provinceSelected?.name,
      firstname: order?.name_customer,
      familyname: "",
      contact_phone: order?.phone,
      address: order?.address,
      to_commune_name:
        order?.delivrey_method === 0
          ? order?.name_city
          : centerSelected?.commune_name,
      to_wilaya_name:
        order?.delivrey_method === 0
          ? order?.name_province
          : centerSelected?.wilaya_name,
      product_list: list,
      is_stopdesk: order?.delivrey_method === 0 ? false : true,
      stopdesk_id:
        order?.delivrey_method === 0 ? null : centerSelected?.center_id,
      price: order?.amount,
      do_insurance: false,
      declared_value: 0,
      height: 0,
      width: 0,
      length: 0,
      weight: 0,
      freeshipping: order?.cost_delivrey === 0,
      has_exchange: 0,
      product_to_collect: null,
    };
  });
};
export const ChangeToOrdersMaystroDelivrey = (orders, panier) => {
  return orders.map((order) => {
    const list = panier.filter(
      (objet) => objet.order_id === order.prefix_order
    );
    let products = [];
    if (list?.length > 0) {
      list.forEach((element) => {
        products.push({
          product_id: element?.product_id,
          quantity: element?.qty,
        });
      });
    }
    return {
      source: 4,
      destination_text: "",
      product_price: order?.amount + order?.cost_delivrey,
      customer_name: order?.name_customer,
      customer_phone: order?.phone,
      express: false,
      wilaya: order?.id_province,
      commune: order?.id_city,
      products: products,
      note_to_driver: order?.note,
      external_order_id: order.prefix_order,
    };
  });
};
export const ChangeToOrdersZRDelivrey = (orders, panier) => {
  return orders.map((order) => {
    const list = panier.filter(
      (objet) => objet.order_id === order.prefix_order
    );

    return {
      TypeLivraison: order?.delivrey_method,
      TypeColis: "0",
      Confrimee: "",
      Client: order?.name_customer,
      MobileA: order?.phone,
      MobileB: order?.email,
      Adresse: order?.address,
      IDWilaya: order?.id_province,
      Commune: order?.name_city,
      Total: order?.amount + order?.cost_delivrey,
      Note: order?.note,
      TProduit: list?.[0]?.name_product,
      id_Externe: order.prefix_order,
      Source: "",
    };
  });
};
export const ChangeToOrdersEcotrack = (orders, panier) => {
  return orders.map((order) => {
    const list = panier.filter(
      (objet) => objet.order_id === order.prefix_order
    );

    return {
      reference: order.prefix_order,
      nom_client: order?.name_customer,
      telephone: order?.phone,
      telephone_2: order?.email,
      adresse:
        order?.address && order?.address !== ""
          ? order?.address
          : `${order?.name_province} ${order?.name_city}`,
      code_postal: "",
      commune: order?.name_city,
      code_wilaya: order?.id_province,
      montant: order?.amount + order?.cost_delivrey,
      remarque: order?.note,
      produit: list?.[0]?.product_id,
      produit_a_recuperer: "",
      boutique: order?.name_shop,
      type: 1,
      stock: 1,
      quantite: list?.[0]?.qty,
      stop_desk: order?.delivrey_method,
    };
  });
};
const getErrorYalitec = (msg) => {
  Swal.fire({
    title: "Attention",
    position: "center",
    icon: "error",
    text: msg,
    showConfirmButton: true,
    confirmButtonColor: "#2399baff",
  });
};
export const ValidationDelivrey = async (delivrey, cmdsSelected) => {
  let ErrorProvinceOrder = 0;
  const data = await getDelivreyByProvinces(delivrey?.id_delivrey);
  const delivreyWilaya = transformDelivreyProvinceData(data)[0];
  for (let index = 0; index < delivreyWilaya?.provinces?.length; index++) {
    const item = delivreyWilaya?.provinces[index];
    for (let index = 0; index < cmdsSelected.length; index++) {
      const order = cmdsSelected[index];
      if (
        item.province_id === order.id_province &&
        ((order.delivrey_method === 0 && item.home_delivrey === 0) ||
          (order.delivrey_method === 1 && item.stop_desk === 0))
      ) {
        ErrorProvinceOrder++;
        const methode =
          order.delivrey_method === 0 ? "Livraison à domicile" : "Stop desk";
        getErrorYalitec(
          "Wilaya " +
            order.name_province +
            " avec livreur " +
            delivrey.name_delivrey +
            " est désactiver avec " +
            methode +
            " de ce commande " +
            order.prefix_order
        );
        return { success: false };
      }
    }
  }

  if (ErrorProvinceOrder !== 0) {
    return { success: false };
  }

  const shopIds = new Set(delivrey?.shops?.map((shop) => shop.id_shop));

  // Trouver les commandes dont les boutiques ne sont pas dans le Set
  const NorderHasShopOutside = cmdsSelected
    .filter((cmd) => !shopIds.has(cmd.id_shop))
    .map((cmd) => cmd.prefix_order);
  // Vérifier s'il y a des commandes dont les boutiques ne correspondent pas
  if (NorderHasShopOutside.length > 0) {
    const msg =
      NorderHasShopOutside.length === 1
        ? `La boutique de la commande ${NorderHasShopOutside.join(
            ", "
          )} ne correspond pas à celles des livreurs.`
        : `Les boutiques des commandes ${NorderHasShopOutside.join(
            ", "
          )} ne correspondent pas à celles des livreurs.`;
    getErrorYalitec(msg);
    return { success: false };
  }

  return { success: true };
};
const checkAPICredential = (cmdsSelected, delivrey, platforme) => {
  //  Checked platforme de delivrey if have cle activation pour tout les boutiques de commande
  let NplatformeHasActiveApi = [];
  for (let index = 0; index < cmdsSelected.length; index++) {
    const cmd = cmdsSelected[index];
    let NShopHasPlatforme = true;
    const shop = delivrey?.shops?.find((shop) => shop.id_shop === cmd.id_shop);
    if (shop) {
      shop?.settings?.forEach((setting) => {
        // Maystro
        if (
          platforme === 1 &&
          setting.name_setting === "Maystro" &&
          setting.active === 1 &&
          setting.column1 !== "" &&
          setting.column1 !== null
        ) {
          NShopHasPlatforme = false;
        }
        // Yalidine
        if (
          platforme === 2 &&
          setting.name_setting === "Yalidine" &&
          setting.active === 1 &&
          setting.column1 !== "" &&
          setting.column1 !== null &&
          setting.column2 !== "" &&
          setting.column2 !== null
        ) {
          NShopHasPlatforme = false;
        }
        // ZRexpress
        if (
          platforme === 3 &&
          setting.name_setting === "ZR" &&
          setting.active === 1 &&
          setting.column1 !== "" &&
          setting.column1 !== null &&
          setting.column2 !== "" &&
          setting.column2 !== null
        ) {
          NShopHasPlatforme = false;
        }
        // DHD
        if (
          platforme === 4 &&
          setting.name_setting === "DHD" &&
          setting.active === 1 &&
          setting.column1 !== "" &&
          setting.column1 !== null
        ) {
          NShopHasPlatforme = false;
        }
      });
      if (NShopHasPlatforme) {
        NplatformeHasActiveApi.push(cmd.prefix_order);
      }
    }
  }
  if (NplatformeHasActiveApi.length > 0) {
    let getNameOfPlatforme = "";
    switch (platforme) {
      case 1:
        getNameOfPlatforme = "Maystro";
        break;
      case 2:
        getNameOfPlatforme = "Yalidine";
        break;
      case 3:
        getNameOfPlatforme = "ZRexpress";
        break;
      case 4:
        getNameOfPlatforme = "DHD Livraison";
        break;
      default:
        break;
    }
    getErrorYalitec(
      `Le plateforme API ${getNameOfPlatforme} est désactivée ou inexistante pour les commandes suivantes : ${NplatformeHasActiveApi.join(
        ","
      )}.`
    );
    return false;
  }
  return true;
};
export const SendOrdersToCompanyDelivrey = async ({
  cmdsSelected,
  delivrey,
  panier,
  setOrdersNoUpload,
  setSuccessPostApi,
  setApiIds,
  centerSelected,
  provinceSelected,
  setCenters,
  handleCenterModel,
  handleCloseCentermodal,
  user,
  branch,
  setProvinces,
  setStopDeskOrders,
  isValid,
  setSuccessPostYalidine,
  setOrdersEcotrack,
}) => {
  const platforme = delivrey?.platform_id;
  if (cmdsSelected.length > 0) {
    if (!isValid) {
      const resp = await ValidationDelivrey(delivrey, cmdsSelected);
      if (!resp.success) {
        return;
      }
    }
    const respCheck = checkAPICredential(cmdsSelected, delivrey, platforme);
    if (!respCheck) {
      return;
    }
    let ordersNoupload = [];

    if (platforme === 1) {
      const orders = ChangeToOrdersMaystroDelivrey(cmdsSelected, panier);
      await postOrderToMaystroDelivrey(
        orders,
        cmdsSelected,
        delivrey,
        ordersNoupload,
        setApiIds
      );
    }
    if (platforme === 2) {
      const { id, token } = await getIDAndTokenYalidin(cmdsSelected, delivrey);
      const hasDelivreyMethode1 = cmdsSelected.some(
        (item) => item.delivrey_method === 1
      );

      if (provinceSelected === null && centerSelected === null) {
        const resp = await getCentersForYalidine(id, token);
        setProvinces(resp.data.wilayaResp);
        setCenters(resp?.data?.centerResp);
        if (hasDelivreyMethode1) {
          setStopDeskOrders(true);
        } else {
          setStopDeskOrders(false);
        }
        handleCenterModel();
      } else {
        const orders = ChangeToOrdersYalitecOrYalidine(
          cmdsSelected,
          panier,
          centerSelected,
          provinceSelected
        );
        const delivreyYalidine = {
          id: delivrey.id_delivrey,
          name: delivrey.name_delivrey,
        };
        try {
          await postOrderToYalidine(
            id,
            token,
            orders,
            delivreyYalidine,
            user,
            branch
          );
          setSuccessPostYalidine(true);
          handleCloseCentermodal();
        } catch (error) {
          setSuccessPostYalidine(false);
          ordersNoupload = cmdsSelected?.map((cmd) => ({
            cmd: cmd.prefix_order,
            msg: error?.response?.data?.error?.message,
          }));
          handleCloseCentermodal();
        }
      }
    }
    if (platforme === 3) {
      const orders = ChangeToOrdersZRDelivrey(cmdsSelected, panier);
      await postOrderToZRDelivrey(
        orders,
        cmdsSelected,
        delivrey,
        ordersNoupload,
        setApiIds
      );
    }
    if (platforme === 4) {
      const orders = ChangeToOrdersEcotrack(cmdsSelected, panier);
      await postOrderToEcotrack({
        cmdsSelected,
        delivrey,
        orders,
        ordersNoupload,
        setOrdersEcotrack,
        branch,
        user,
        ecotrack: ecotrackDelivrey.DHD,
      });
    }
    if (platforme === 5) {
      const orders = ChangeToOrdersEcotrack(cmdsSelected, panier);
      await postOrderToEcotrack({
        cmdsSelected,
        delivrey,
        orders,
        ordersNoupload,
        setOrdersEcotrack,
        branch,
        user,
        ecotrack: ecotrackDelivrey.Noest,
      });
    }
    setOrdersNoUpload(ordersNoupload);
    setSuccessPostApi(true);
  }
};

export const controleChangeDelivrey = async (
  delivrey,
  cmdsSelected,
  setLoading
) => {
  const resp = await ValidationDelivrey(delivrey, cmdsSelected, setLoading);
  if (!resp.success) {
    return true;
  }
  const respCheck = checkAPICredential(
    cmdsSelected,
    delivrey,
    delivrey?.platform_id,
    setLoading
  );
  if (resp.success && respCheck) {
    return true;
  }
  return false;
};
const getCentersForYalidine = async (yalidine_id, yalidine_token) => {
  // const URL_YALIDINE = process.env.REACT_APP_YALIDINE_API_URL;
  return await axios
    .post(`${apiURL}/yalidine/centers-wilayas`, {
      key: yalidine_id,
      token: yalidine_token,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
const postOrderToYalidine = async (
  key,
  token,
  orders,
  delivrey,
  user,
  branch
) => {
  const resp = await axios.post(`${apiURL}/yalidine`, {
    key,
    token,
    orders,
    user,
    delivrey,
    branch,
  });
  return resp;
};
const getIDAndTokenYalidin = (cmdsSelected, delivrey) => {
  let id = "id";
  let token = "token";
  for (let index = 0; index < cmdsSelected.length; index++) {
    const cmd = cmdsSelected[index];
    // const cmd = cmdsSelected.find((cmd) => cmd.prefix_order === order.order_id);
    delivrey?.shops?.forEach((shop) => {
      if (shop.id_shop === cmd.id_shop) {
        if (delivrey.platform_id === 2) {
          shop?.settings?.forEach((setting) => {
            if (setting?.name_setting === "Yalidine") {
              id = setting?.column1;
              token = setting?.column2;
            }
          });
        }
      }
    });
  }
  return { id, token };
};
export const postOrderToMaystroDelivrey = async (
  orders,
  cmdsSelected,
  delivrey,
  ordersNoupload,
  setApiIds
) => {
  // const API_URL = process.env.REACT_APP_MAYSTRO_API_URL;
  const API_URL = process.env.REACT_APP_MAYSTRO_API_URL;
  for (let index = 0; index < orders.length; index++) {
    const order = orders[index];

    const cmd = cmdsSelected.find(
      (cmd) => cmd.prefix_order === order.external_order_id
    );
    delete order.external_order_id;
    let token = { value: "token" };
    const shop = delivrey?.shops?.find(
      (shop) => shop?.id_shop === cmd?.id_shop
    );
    if (shop && delivrey?.platform_id === 1) {
      const setting = shop?.settings?.find(
        (setting) => setting?.name_setting === "Maystro"
      );
      if (setting) {
        token.value = setting?.column1;
      }
    }
    await axios
      .post(
        `${API_URL}/orders_store/`,
        { ...order },
        {
          headers: {
            Authorization: `Token ${token.value}`,
          },
        }
      )
      .then((resp) => {
        setApiIds((prev) => [
          ...prev,
          {
            dbId: cmd?.prefix_order,
            apiId: resp?.data?.id,
            displayId: resp.data?.display_id,
          },
        ]);
      })
      .catch((error) => {
        console.log(error);
        console.log(orders);
        ordersNoupload.push(cmd?.prefix_order);
      });
  }
};
export const postOrderToZRDelivrey = async (
  orders,
  cmdsSelected,
  delivrey,
  ordersNoupload,
  setApiIds
) => {
  let token = "token";
  let key = "cle";
  delivrey?.shops?.forEach((shop) => {
    if (shop.id_shop === cmdsSelected?.[0]?.id_shop) {
      if (delivrey.platform_id === 3) {
        shop?.settings?.forEach((setting) => {
          if (setting?.name_setting === "ZR") {
            key = setting?.column1;
            token = setting?.column2;
          }
        });
      }
    }
  });
  // for (let index = 0; index < orders.length; index++) {
  //   const order = orders[index];
  //   const cmd = cmdsSelected.find(
  //     (cmd) => cmd.prefix_order === order.id_Externe
  //   );

  // }
  await axios
    .post(
      `${apiURL}/zrexpress/add_colis`,
      { Colis: JSON.stringify(orders) },
      {
        params: {
          token,
          key,
        },
      }
    )
    .then((resp) => {
      if (resp.data?.length > 0) {
        const extractedData = resp.data.map((cmd) => ({
          dbId: cmd.id_Externe,
          apiId: cmd.Tracking,
          displayId: cmd.Tracking,
        }));
        setApiIds((prev) => [...prev, ...extractedData]);
      } else {
        ordersNoupload.push(cmdsSelected);
      }
    })
    .catch((error) => {
      ordersNoupload.push(cmdsSelected);
    });
};
export const postOrderToEcotrack = async ({
  orders,
  cmdsSelected,
  delivrey,
  setOrdersEcotrack,
  ordersNoupload,
  user,
  branch,
  ecotrack,
}) => {
  let token = "cle";
  delivrey?.shops?.forEach((shop) => {
    if (shop.id_shop === cmdsSelected?.[0]?.id_shop) {
      if (delivrey.platform_id === 4) {
        shop?.settings?.forEach((setting) => {
          if (
            setting?.name_setting === ecotrackDelivrey.DHD ||
            setting?.name_setting === ecotrackDelivrey.Noest
          ) {
            token = setting?.column1;
          }
        });
      }
    }
  });

  await axios
    .post(
      `${apiURL}/ecotrack/add_colis`,
      { orders: JSON.stringify(orders), delivrey, user, branch },

      {
        params: {
          token,
          ecotrack,
        },
      }
    )
    .then((resp) => {
      if (resp?.data?.orders?.length > 0) {
        setOrdersEcotrack(resp?.data?.orders);
      }
    })
    .catch((error) => {
      ordersNoupload = cmdsSelected?.map((cmd) => cmd.prefix_order);
    });
};

export const ecotrackDelivrey = Object.freeze({
  DHD: "DHD",
  Noest: "Noest",
});
