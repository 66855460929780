import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { filterValue } from "../types";
import i18next from "i18next";

const FilterItem = ({ item, filterValue, handleValue }) => (
  <Button
    sx={{ textTransform: "none" }}
    onClick={async () => await handleValue(item.value)}
    variant={item.value === filterValue ? "contained" : "outlined"}
  >
    {item.label}
  </Button>
);
const FilterTime = ({ handleFilter, filter }) => {
  const { t } = useTranslation("global");

  const items = [
    {
      id: 1,
      label: t("overview.today"),
      value: filterValue.TODAY,
    },
    {
      id: 2,
      label: t("overview.yesterday"),
      value: filterValue.YESTERDAY,
    },
    {
      id: 3,
      label: t("overview.week"),
      value: filterValue.WEEK,
    },
    {
      id: 4,
      label: t("overview.month"),
      value: filterValue.MONTH,
    },
    {
      id: 5,
      label: t("overview.all"),
      value: filterValue.ALL,
    },
  ];
  const handleValue = async (value) => {
    await handleFilter(value);
  };
  return (
    <Box
      display={"flex"}
      flexDirection={i18next.language === "ar" ? "row-reverse" : "row"}
      width="100%"
      alignItems={"center"}
      gap={"10px"}
      flexWrap={"wrap"}
    >
      {items.map((filterItem) => (
        <FilterItem
          key={filterItem.id}
          item={filterItem}
          filterValue={filter}
          handleValue={handleValue}
        />
      ))}
    </Box>
  );
};

export default FilterTime;
