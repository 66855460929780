import { Box } from "@mui/material";
import React from "react";
import TopProducts from "./Components/TopProducts";
import StorePerformance from "./Components/StorePerformance";
import MostStates from "./Components/MostStates";
import UseOverviewPresenter from "./Overview.Presenter";

const Overview = () => {
  const {
    products,
    states,
    loadingProducts,
    loadingStates,
    handleFilterProducts,
    handleFilterStates,
    filterProducts,
    filterStates,
    paginateStates,
    page,
    filterShop,
    handleFilterShop,
    shops,
    shopSelected,
    handleChangeShop,
    dataShops,
    loadingShop,
    delivredRate,
  } = UseOverviewPresenter();
  return (
    <Box
      display={"flex"}
      sx={{
        "@media (max-width: 960px)": {
          flexDirection: "column",
        },
      }}
      alignItems={"flex-start"}
      gap={"10px"}
    >
      <TopProducts
        filter={filterProducts}
        handleFilter={handleFilterProducts}
        loading={loadingProducts}
        data={products}
      />
      <StorePerformance
        delivredRate={delivredRate}
        data={dataShops}
        handleChangeShop={handleChangeShop}
        shopsSelected={shopSelected}
        shops={shops}
        filter={filterShop}
        loading={loadingShop}
        handleFilter={handleFilterShop}
      />
      <MostStates
        paginate={paginateStates}
        page={page}
        loading={loadingStates}
        filter={filterStates}
        handleFilter={handleFilterStates}
        data={states}
      />
    </Box>
  );
};

export default Overview;
