import { Box, Skeleton } from "@mui/material";
import React from "react";

export const SkelotonItems = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "414px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      {[1, 2, 3, 4, 5].map((value) => (
        <Skeleton variant="rounded" width={"100%"} height={60} />
      ))}
    </Box>
  );
};

export const PieChartWithSkeleton = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap={"20px"}
    height={"445px"}
  >
    <Skeleton variant="text" width={200} height={30} sx={{ mt: 2 }} />
    <Skeleton variant="circular" width={300} height={300} />
  </Box>
);
