import { ChangeFormStringToDate, formatDateFr } from "setup/services";

const ColumnsWoo = (t) => [
  // {
  //   title: "ID",
  //   field: "api_draft",
  //   cellStyle: {
  //     width: 350,
  //   },
  // },
  {
    title: t("Client"),
    field: "customer",
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Téléphone"),
    field: "phone",
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Wilaya"),
    field: "province",
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Commune"),
    field: "city",
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Adresse"),
    field: "address",
  },

  {
    title: t("Produits"),
    field: "total",
    render: (params) => {
      let name = [];
      if (params?.products?.length > 0) {
        params.products.forEach((element) => {
          name.push(
            `{ ${t("Produit")} : ${element.ref},${t("Qte")} : ${element.qty} } `
          );
        });
        return <div>{name.join(" , ")}</div>;
      }
    },
    cellStyle: {
      width: 300,
    },
  },
  {
    title: t("Date"),
    render: (params) => {
      return ChangeFormStringToDate(params.create_time);
    },
  },
];

export default ColumnsWoo;
