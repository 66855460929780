import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Badge,
  Box,
  Button,
  IconButton,
  Paper,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import MaterialTable from "material-table";
import { tableIcons } from "../../../setup/TableIcons";
import GoogleIcon from "@mui/icons-material/Google";
import store from "store";
import file_excel from "../../../assets/Excel/upslice_sheet.xlsx";

import expirePlugin from "store/plugins/expire";
import {
  DeleteBrouillon,
  ToastStatus,
  getAllShops,
  getAllCustomers,
  getAllProducts,
  getAllProvince,
  getBrouillon,
  localizationTable,
  PostOrderSheet,
  HeaderStyle,
  RowStyle,
  AuthWithGoogle,
  getSheetFiles,
  handleErrorLimitOrder,
} from "../../../setup/services";
import ColumnsSheet from "../../../components/Columns/ColumnsSheet";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "react-toastify/dist/ReactToastify.css";

import { TypeContext } from "App";
import { SiGooglesheets } from "react-icons/si";
import Title from "components/Title";
import { ToastContainer, toast } from "react-toastify";
import DialogFiles from "components/Sheet/DialogFiles";
import useAuth from "hooks/useAuth";
// const UpdateBrouillon = lazy(() => import("components/Sheet/UpdateBrouillon"));
import UpdateBrouillon from "components/Sheet/UpdateBrouillon";
import { useSearchParams } from "react-router-dom";
import Loader from "components/Loader/Loader";
import CustomBox from "components/CustomBox";
import { TbTableFilled } from "react-icons/tb";
import ButtonLoading from "components/ButtonLoading";
import { useTranslation } from "react-i18next";
import { ImDownload3 } from "react-icons/im";
const ListeSheet = () => {
  const { auth } = useAuth();
  const [searchParams] = useSearchParams();
  const [, setChangeType] = useContext(TypeContext);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState("");
  const [update, setUpdate] = useState(false);
  const theme = createTheme({
    typography: {
      fontFamily: ['"Segoe UI"', "sans-serif"].join(","),
      fontSize: 14,
    },
  });
  const [t] = useTranslation("global");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [products, setProducts] = useState([]);
  const [success, setSuccess] = useState(false);
  const [countSelected, setCountSelected] = useState([]);
  const [SelectedCmd, setSelectedCmd] = useState([]);
  const colOrders = useMemo(() => ColumnsSheet(t), [t]);
  const [openModel, setOpenModel] = useState(false);
  const handleOpenModel = () => setOpenModel(true);
  const handleCloseModel = () => setOpenModel(false);
  const [openModelUpdate, setOpenModelUpdate] = useState(false);
  const handleOpenModelUpdate = () => setOpenModelUpdate(true);
  const handleCloseModelUpdate = () => setOpenModelUpdate(false);

  const [errorImport, setErrorImport] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingButtonFile, setLoadingButtonFile] = useState(false);
  const [drafts, setDrafts] = useState([]);
  const [shops, setShops] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [googleTokens, setGoogleTokens] = useState(null);
  const AlertSucess = () => {
    toast.success(t("Commande(s) importée(s) avec succès"), {
      ...ToastStatus,
    });
  };
  useEffect(() => {
    if (!auth.branch) {
      return;
    }
    getAllShops(auth.branch).then((data) => {
      setShops(data);
    });
    getAllProvince(auth.branch).then((data) => {
      setProvinces(data);
    });
    getAllProducts(auth.branch).then((data) => {
      setProducts(data);
    });
    getAllCustomers(undefined, auth.branch).then((data) => {
      setCustomers(data);
    });
    const storedTokens = store.get("tokens");
    if (storedTokens) {
      setGoogleTokens(storedTokens);
    }
    const authValue = searchParams.get("code");
    if (authValue) {
      setErrorImport(false);
      setLoadingFile(true);
      const form = new FormData();
      form.append("code", authValue);
      const values = Object.fromEntries(form);
      getSheetFiles(values)
        .then((data) => {
          handleOpenModel();
          setFiles(data?.files);
          store.addPlugin(expirePlugin);
          const oneDay = 24 * 60 * 60 * 1000;
          store.set("tokens", data?.tokens, oneDay);
          setGoogleTokens(data?.tokens);
        })
        .catch(() => {
          setErrorImport(true);
        })
        .finally(() => {
          setLoadingFile(false);
        });
    }
    getBrouillon(0, auth.branch).then((data) => {
      setDrafts(data);
    });
  }, [update, auth.branch]);

  // const remove = (ref) => {
  //   DeleteListCmd(ref);
  //   setUpdate((prev) => !prev);
  // };

  const getDataFiles = () => {
    setLoadingButtonFile(true);
    const form = new FormData();
    form.append("tokens", JSON.stringify(googleTokens));
    const values = Object.fromEntries(form);
    getSheetFiles(values)
      .then((data) => {
        handleOpenModel();
        setFiles(data?.files);
        store.addPlugin(expirePlugin);
        const oneDay = 24 * 60 * 60 * 1000;
        store.set("tokens", data?.tokens, new Date().getTime() + oneDay);
        setGoogleTokens(data?.tokens);
      })
      .catch(() => {
        setErrorImport(true);
      })
      .finally(() => {
        setLoadingButtonFile(false);
      });
  };
  const validateSuppression = (ids) => {
    if (ids.length > 0) {
      const alert =
        ids.length === 1
          ? `${t("Vous voulez vraiment supprimer")} ${t("le")} ${t(
              "brouillon"
            )} #${ids[0]} ?`
          : `${t("Vous voulez vraiment supprimer")} ${t("les")} ${
              ids.length
            } ${t("brouillons")} `;
      const confirmed = window.confirm(alert);
      if (confirmed) {
        DeleteBrouillon(ids).then(() => {
          setUpdate((prev) => !prev);
          setChangeType((prev) => !prev);
        });
      }
    }
  };
  // seterrror supprimer et remplcer pour ajouter ce commande dans dbb
  // ajouter function getIdsOfBoutiques un prefix cmd
  // chnager then data of idsbutique par autre

  let index = 0; // Initialisez un index pour suivre l'itération en cours
  // const processNextCommande = async () => {
  //   if (index < orders.length) {
  //     // Tant qu'il y a des orders à traiter
  //     const element = orders[index];
  //     await PostOrderSheet(element);
  //     // // Incrémentez l'index pour la prochaine itération
  //     index++; // 2000 millisecondes (2 secondes)
  //   } else {
  //   }
  // };
  // useEffect(() => {
  //   if (file !== "") {
  //     setSuccessImport(false);
  //     setSuccess(false);
  //     getDataFileSheet(file);
  //   }
  // }, [file]);
  // useEffect(() => {
  //   if (file !== "") {
  //     setSuccessImport(false);
  //     setSuccess(false);
  //     getDataFileSheet(file);
  //   }
  // }, [file]);
  const sendOrder = async () => {
    // if (orders.length > 0) {
    setLoaded(true);
    const form = new FormData();
    form.append("user", auth.idUser);
    form.append("branch", auth.branch);
    form.append("tokens", JSON.stringify(googleTokens));
    form.append("file", file);
    form.append("files", JSON.stringify(files));
    const formData = Object.fromEntries(form);
    PostOrderSheet(formData)
      .then(() => {
        setLoaded(false);
        setChangeType((prev) => !prev);
        AlertSucess();
        handleCloseModel();
        setUpdate((prev) => !prev);
      })
      .catch((error) => {
        handleCloseModel();
        handleErrorLimitOrder(error);
        setLoaded(false);
        toast.error(t("Échec d'importation des commandes"), {
          ...ToastStatus,
        });
      });
    // }
  };
  // const sendCharge = () => {
  //   if (orders.length > 0) {
  //     setValider(true);
  //   } else {
  //     setValider(false);
  //   }
  // };
  const AuthGoogle = async () => {
    AuthWithGoogle()
      .then((data) => {
        window.location.href = data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  if (loadingFile) {
    return <Loader />;
  }
  const deleteStoreTokens = () => {
    store.clearAll();
    setGoogleTokens(null);
    setErrorImport(false);
  };
  return (
    <>
      {/* <div className="header_addCmd-d_flex">
        <SiGooglesheets className="icon_cmd icon_sheet" />
        <p>Orders Google Sheet</p>
      </div> */}
      <ToastContainer />
      <Title title={t("Commandes Google Sheet")} />
      <CustomBox title={t("Importez vos commandes avec Google Sheet")} />
      <DialogFiles
        files={files}
        file={file}
        setFile={setFile}
        handleClose={handleCloseModel}
        open={openModel}
        sendOrder={sendOrder}
        loaded={loaded}
        success={success}
        auth={auth}
        // sendCharge={sendCharge}
      />
      <Box
        component={Paper}
        elevation={3}
        padding={2}
        borderRadius={2}
        marginBottom={2}
      >
        <div
          className="header_addCmd-d_flex"
          style={{ marginTop: 10, flexWrap: "wrap" }}
        >
          <div
            style={{
              backgroundColor: "#199454",
              borderRadius: "50%",
              height: 30,
              width: 30,
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="pickup-title"
          >
            <SiGooglesheets fontSize={20} />
          </div>
          <p>{t("Commandes Google Sheet")}</p>
        </div>
        <div className="import-modele">
          {t("Fichier à importer")} (
          <span className="modele">
            <a href={file_excel} download="sheet_orders.xlsx">
              {" "}
              {t("Modèle")} <ImDownload3 />
            </a>
          </span>
          )
        </div>
        <div className="sheet-btns">
          <Box display={"flex"} columnGap={2}>
            {googleTokens === null ? (
              <Button
                startIcon={<GoogleIcon />}
                style={{ textTransform: "none" }}
                variant="contained"
                onClick={AuthGoogle}
              >
                {t("Se connecter")}
              </Button>
            ) : (
              <Button
                startIcon={<GoogleIcon />}
                style={{ textTransform: "none" }}
                variant="contained"
                onClick={deleteStoreTokens}
              >
                {t("Se Déconnecter")}
              </Button>
            )}
            {
              // user?.isAuth === false ? (
              // <LoginButton setUser={setUser} clientId={CLIENT_ID} />
              // ) : (
              // <LogoutButton setUser={setUser} clientId={CLIENT_ID} />
              // )
            }
            {loadingButtonFile ? (
              <ButtonLoading />
            ) : (
              <Button
                startIcon={
                  <TbTableFilled
                    color={
                      googleTokens === null ? "rgba(0, 0, 0, 0.2)" : "#01579b"
                    }
                    fontSize={20}
                  />
                }
                style={{ textTransform: "none" }}
                variant="outlined"
                onClick={getDataFiles}
                disabled={googleTokens === null}
              >
                {t("Feuilles de calcul")}
              </Button>
            )}
          </Box>
          <Box display={"flex"} columnGap={2}>
            {/* {loadingButtonFile ? (
              <CircularProgress sx={{ fontSize: "24px" }} />
            ) : (
              <Box component={Paper} paddingX={1}>
                <Tooltip title="Importer fichier">
                  <IconButton
                    onClick={getDataFiles}
                    disabled={googleTokens === null}
                  >
                    <BsDownload
                      style={{ color: "#a3a3a3" }}
                      fontSize={"24px"}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            )} */}
            <Box component={Paper} paddingX={1}>
              <Tooltip title={t("Modifier un brouillon")}>
                <IconButton
                  onClick={handleOpenModelUpdate}
                  disabled={countSelected.length !== 1}
                >
                  <EditIcon color="info" fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box component={Paper} paddingX={1}>
              <Badge badgeContent={countSelected.length} color="error">
                <Tooltip title={t("Supprimer un brouillon")}>
                  <IconButton
                    onClick={() => validateSuppression(countSelected)}
                    disabled={countSelected.length === 0}
                  >
                    <DeleteIcon color="error" fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Badge>
            </Box>
          </Box>
          {/* <button
            style={{ textTransform: "none" }}
            onClick={() => validateSuppression(countSelected)}
            className="btn_delete-sheet"
          >
             */}
          {/* <div className="icon_delete-sheet">
              <DeleteIcon color="error" />
            </div>
            <span className="span_delete-sheet">Supprimer </span>
          </button> */}
          {/* <button
            disabled={!buttonImport}
            style={{ textTransform: "none" }}
            onClick={() => handleOpenModel()}
            variant="outlined"
            color="error"
            className="btn_delete-sheet"
          >
            <div className="icon_delete-sheet">
              <BsDownload style={{ color: "#a3a3a3" }} fontSize={"22px"} />
            </div>
            <span className="span_delete-sheet">Importer</span>
          </button> */}
        </div>
      </Box>
      {errorImport && googleTokens === null && (
        <Alert severity="error">{t("Échec d'importer les fichiers")}</Alert>
      )}
      {SelectedCmd && SelectedCmd.length > 0 && countSelected.length > 0 && (
        <UpdateBrouillon
          form={SelectedCmd[0]}
          branch={auth.branch}
          setUpdate={setUpdate}
          open={openModelUpdate}
          handleClose={handleCloseModelUpdate}
          shops={shops}
          customers={customers}
          provinces={provinces}
          cities={cities}
          productsDB={products}
          setCities={setCities}
          setChangeType={setChangeType}
          setSuccess={setSuccess}
          auth={auth}
          setCountSelected={setCountSelected}
          setSelectedCmd={setSelectedCmd}
        />
      )}

      <ThemeProvider theme={theme}>
        <MaterialTable
          title={null}
          localization={localizationTable}
          options={{
            pageSize: 10,
            selection: true,
            selectionProps: (rowData) => ({
              checked: countSelected.includes(rowData.idraft),
            }),
            showSelectAllCheckbox: true,
            headerStyle: HeaderStyle(),
            rowStyle: RowStyle(),
          }}
          icons={tableIcons}
          columns={colOrders}
          data={drafts}
          onSelectionChange={(rows) => {
            setCountSelected(rows.map((row) => row.idraft));
            setSelectedCmd([rows[0]]);
          }}
          // actions={[
          //   (rowData) => ({
          //     icon: () =>
          //       countSelected.length === 1 ? (
          //         <MdEdit className="edit_icon-table icon_table" />
          //       ) : (
          //         ""
          //       ),
          //     tooltip: "Modifier brouillon",
          //     onClick: () => {
          //       handleOpenModelUpdate();
          //     },
          //   }),

          //   (rowData) => ({
          //     icon: () =>
          //       countSelected.length === 1 ? (
          //         <MdDelete fontSize={20} className="delete_icon-table" />
          //       ) : (
          //         ""
          //       ),
          //     // countSelected === 1 ? (
          //     tooltip: "Supprimer brouillon",
          //     onClick: () => {
          //       validateSuppression(countSelected);
          //     },
          //   }),
          // ]}
        />
      </ThemeProvider>
    </>
  );
};

export default ListeSheet;
