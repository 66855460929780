import {
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import colors from "shared/colors";
import emptyProduct from "../../../../assets/images/not-found.png";

const OverviewItemBtn = ({
  star,
  name,
  ordersCount,
  ordersDelivred,
  t,
  isProduct,
  id,
  image,
}) => {
  return (
    <ListItemButton alignItems="center">
      <Typography
        component="span"
        variant="body2"
        sx={{
          marginRight: "5px",
          color: !star ? colors.goodBlack : colors.warning,
          fontWeight: "bold",
          fontFamily: "Open Sans",
          display: "inline",
          fontSize: "12px",
        }}
      >
        {id < 10 ? `0${id}` : id}
      </Typography>
      <ListItemAvatar>
        {isProduct && (
          <img
            height={50}
            width={50}
            style={{ borderRadius: "10px" }}
            src={
              image
                ? `${process.env.REACT_APP_API_URL}/public/images/${image}`
                : emptyProduct
            }
            alt={name}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              sx={{
                color: colors.goodBlack,
                fontWeight: "bold",
                fontFamily: "Open Sans",
                display: "inline",
                fontSize: "12px",
              }}
            >
              {ordersCount} {t("Commandes")}
            </Typography>
          </React.Fragment>
        }
      />
      <Typography
        component="span"
        variant="body2"
        sx={{
          color: colors.goodBlack,
          fontWeight: "bold",
          fontFamily: "Open Sans",
          display: "inline",
          fontSize: "18px",
        }}
      >
        {ordersDelivred}
      </Typography>
    </ListItemButton>
  );
};

export default OverviewItemBtn;
