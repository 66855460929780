import { List } from "@mui/material";
import React from "react";
import OverviewItemBtn from "./OverviewItemBtn";

const OverviewItems = ({ data, t, isProduct }) => {
  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {data &&
        data?.map((item, index) => (
          <OverviewItemBtn
            star={index === 0}
            isProduct={isProduct}
            t={t}
            image={item?.image}
            key={index}
            name={item.name}
            ordersCount={item?.ordersCount ?? 0}
            ordersDelivred={item?.ordersDelivred ?? 0}
            id={index + 1}
          />
        ))}
    </List>
  );
};

export default OverviewItems;
