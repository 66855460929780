import {
  Box,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import FilterTime from "./FilterTime";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import colors from "shared/colors";
import i18next from "i18next";

const HeaderSection = ({ icon, title }) => (
  <Box
    display={"flex"}
    alignItems={"center"}
    gap={"10px"}
    flexDirection={i18next.language === "ar" ? "row-reverse" : "row"}
  >
    {icon}{" "}
    <Typography
      fontFamily={"Open Sans"}
      color={colors.mainColor}
      variant="h6"
      component="h6"
      fontWeight={"700"}
    >
      {title}
    </Typography>
  </Box>
);
const OverviewItem = ({
  filter,
  handleFilter,
  title,
  icon,
  children,
  shops,
  handleChangeShop,
  shopsSelected,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelcted = async (id) => {
    await handleChangeShop(id);
  };
  return (
    <Card sx={{ padding: "10px", maxHeight: "600px" }}>
      <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
        <FilterTime filter={filter} handleFilter={handleFilter} />
        {shops && shops?.length > 0 ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={i18next.language === "ar" ? "row-reverse" : "row"}
          >
            <HeaderSection icon={icon} title={title} />
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              slotProps={{
                paper: {
                  style: {
                    maxHeight: 48 * 4.5,
                    width: "20ch",
                  },
                },
              }}
            >
              {shops.map((option) => (
                <MenuItem
                  key={option.id_shop}
                  selected={option.id_shop === shopsSelected}
                  onClick={async () => await handleSelcted(option.id_shop)}
                >
                  {option.name_shop}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ) : (
          <HeaderSection icon={icon} title={title} />
        )}
      </Box>
      {children}
    </Card>
  );
};

export default OverviewItem;
