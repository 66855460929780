import React from "react";
import { useTranslation } from "react-i18next";
import OverviewItem from "./OverviewItem";
import colors from "shared/colors";
import { PieChart } from "@mui/x-charts";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BarChart } from "@mui/icons-material";
import { PieChartWithSkeleton } from "./SkelotonOverview";
const StorePerformance = ({
  handleFilter,
  filter,
  shops,
  handleChangeShop,
  shopsSelected,
  data,
  loading,
  delivredRate,
}) => {
  const { t } = useTranslation("global");
  function PieCenterLabel({ deliveredRate, t }) {
    const StyledText = styled("text")(({ theme }) => ({
      fill: theme.palette.text.primary,
      textAnchor: "middle",
      dominantBaseline: "central",
      fontWeight: "bold",
      fontSize: 12,
    }));
    return (
      <>
        {/* Affichage du deliveredRate en haut */}
        <StyledText x={200} y={180}>
          {deliveredRate}
        </StyledText>
        {/* Affichage du Taux de livraison en bas */}
        <StyledText x={200} y={220}>
          {t("Taux de livraison")}
        </StyledText>
      </>
    );
  }
  return (
    <OverviewItem
      filter={filter}
      handleFilter={handleFilter}
      title={t("overview.store_performance")}
      shops={shops}
      icon={<BarChart sx={{ color: colors.mainColor }} />}
      handleChangeShop={handleChangeShop}
      shopsSelected={shopsSelected}
    >
      {loading ? (
        <PieChartWithSkeleton />
      ) : (
        <Box
          flexGrow={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"445px"}
        >
          <PieChart
            series={[
              {
                data: data,
                innerRadius: 70,
                outerRadius: 150,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: -200,
                endAngle: 225,
                cx: 200,
                cy: 200,
              },
            ]}
            height={380}
            slotProps={{
              legend: {
                position: { vertical: "top" },
                direction: "row",
              },
            }}
          >
            <PieCenterLabel deliveredRate={delivredRate} t={t} />
          </PieChart>
        </Box>
      )}
    </OverviewItem>
  );
};

export default StorePerformance;
