import React from "react";
import OverviewItem from "./OverviewItem";
import { useTranslation } from "react-i18next";
import PinDropIcon from "@mui/icons-material/PinDrop";
import colors from "shared/colors";
import OverviewItems from "./OverviewItems";
import { SkelotonItems } from "./SkelotonOverview";
import { Box, Button } from "@mui/material";
const MostStates = ({
  paginate,
  page,
  data,
  loading,
  filter,
  handleFilter,
}) => {
  const { t } = useTranslation("global");
  return (
    <OverviewItem
      filter={filter}
      handleFilter={handleFilter}
      title={t("overview.most_states")}
      icon={<PinDropIcon sx={{ color: colors.mainColor }} />}
    >
      <Box maxHeight={"418px"} sx={{ overflowY: "scroll" }}>
        {loading ? <SkelotonItems /> : <OverviewItems t={t} data={data} />}
      </Box>
      {page < 12 ? (
        <Button variant="outlined" fullWidth onClick={() => paginate()}>
          {" "}
          {t("Plus")}...
        </Button>
      ) : (
        <Button variant="outlined" fullWidth onClick={() => paginate(true)}>
          {" "}
          {t("less")}...
        </Button>
      )}
    </OverviewItem>
  );
};

export default MostStates;
