import React from "react";
import OverviewItem from "./OverviewItem";
import { useTranslation } from "react-i18next";
import VerifiedIcon from "@mui/icons-material/Verified";
import colors from "shared/colors";
import OverviewItems from "./OverviewItems";
import { SkelotonItems } from "./SkelotonOverview";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TopProducts = ({ data, loading, filter, handleFilter }) => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  return (
    <OverviewItem
      filter={filter}
      handleFilter={handleFilter}
      title={t("overview.top_product")}
      icon={<VerifiedIcon sx={{ color: colors.mainColor }} />}
    >
      {loading ? (
        <SkelotonItems />
      ) : (
        <OverviewItems isProduct t={t} data={data} />
      )}

      <Button
        onClick={() => navigate("/statistique/produit")}
        on
        variant="outlined"
        fullWidth
      >
        {" "}
        {t("Plus")}...
      </Button>
    </OverviewItem>
  );
};

export default TopProducts;
