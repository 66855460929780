import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { filterValue } from "./types";
import {
  getAllShops,
  getMostStates,
  getReportShop,
  getTopProducts,
} from "setup/services";
import colors from "shared/colors";
import { useTranslation } from "react-i18next";

const UseOverviewPresenter = () => {
  const { auth } = useAuth();
  const { t } = useTranslation("global");
  const initialeStateShop = [
    { label: t("Livrée"), value: 40, color: colors.success, id: 1 },
    { label: t("Retourné"), value: 60, color: colors.warning, id: 2 },
  ];
  const [dataShops, setDataShops] = useState(initialeStateShop);

  useEffect(() => {
    setDataShops((prevState) =>
      prevState.map((item) => ({
        ...item,
        label: t(item.label),
      }))
    );
  }, [t]);
  const [products, setProducts] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingStates, setLoadingStates] = useState(true);
  const [loadingShop, setLoadingShop] = useState(true);
  const [filterProducts, setFilterProducts] = useState(filterValue.ALL);
  const [filterStates, setFilterStates] = useState(filterValue.ALL);
  const [filterShop, setFilterShop] = useState(filterValue.ALL);
  const [shops, setShops] = useState([]);
  const [page, setPage] = useState(1);
  const [shopSelected, setShopSelected] = useState();
  const [delivredRate, setDelivredRate] = useState("0%");
  const fetchTopProduct = async ({ filter }) => {
    setLoadingProducts(true);
    try {
      const data = await getTopProducts({ branch: auth?.branch, filter });
      setProducts(data);
    } finally {
      setLoadingProducts(false);
    }
  };
  const fetchReportShop = async ({ filter, shopId }) => {
    setLoadingShop(true);
    try {
      const data = await getReportShop({ shopId, filter });
      if (data?.length > 0) {
        const { ordersReturned, ordersDelivered } = data[0];
        const Rate = (
          (ordersDelivered / (ordersDelivered + ordersReturned)) *
          100
        ).toFixed(2);
        setDelivredRate(`${Rate}%`);
        setDataShops((prev) => {
          return prev.map((item) => {
            if (item.id === 1) {
              return {
                ...item,
                value: ordersDelivered,
              };
            } else {
              return {
                ...item,
                value: ordersReturned,
              };
            }
          });
        });
      }
    } finally {
      setLoadingShop(false);
    }
  };
  const fetchMostStates = ({ filter, page }) => {
    setLoadingStates(true);
    getMostStates({ branch: auth?.branch, filter, page })
      .then((data) => {
        if (page === 1) {
          setStates(data);
          return;
        }
        setStates((prev) => [...prev, ...data]);
      })
      .finally(() => {
        setLoadingStates(false);
      });
  };
  useEffect(() => {
    if (!auth?.branch) {
      return;
    }

    async function fetchData() {
      await fetchTopProduct({ filter: filterValue.ALL });
      await fetchMostStates({ filter: filterValue.ALL, page: 1 });
      getAllShops(auth.branch).then(async (data) => {
        if (data?.length > 0) {
          const shopId = data[0]?.id_shop;
          await fetchReportShop({ filter: filterValue.ALL, shopId });
          setShopSelected(shopId);
          setShops(data);
        }
      });
    }
    fetchData();
  }, [auth.branch]);
  const handleFilterProducts = async (value) => {
    await fetchTopProduct({ filter: value });
    setFilterProducts(value);
  };
  const paginateStates = async (less) => {
    if (less) {
      setPage(1);
      await fetchMostStates({ filter: filterStates, page: 1 });
      return;
    }
    if (page < 12) {
      await fetchMostStates({ filter: filterStates, page: page + 1 });
    }
    setPage((prev) => prev + 1);
  };
  const handleFilterStates = async (value) => {
    await fetchMostStates({ filter: value, page });
    setFilterStates(value);
  };
  const handleFilterShop = async (value) => {
    await fetchReportShop({ filter: value, shopId: shopSelected });
    setFilterShop(value);
  };
  const handleChangeShop = async (id) => {
    await fetchReportShop({ filter: filterShop, shopId: id });
    setShopSelected(id);
  };
  return {
    products,
    states,
    loadingProducts,
    loadingStates,
    handleFilterProducts,
    handleFilterStates,
    filterProducts,
    filterStates,
    paginateStates,
    page,
    handleFilterShop,
    filterShop,
    shops,
    shopSelected,
    handleChangeShop,
    dataShops,
    loadingShop,
    delivredRate,
  };
};

export default UseOverviewPresenter;
